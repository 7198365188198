import React from 'react';
import "./approvalpage.css";
import logo from "../../assets/psm-logo.png"

const DeclinedPage = () => {
  return (
    <div className='approval-page-container'>
        <img className='approval-page-logo' src={logo} alt="" />
        <h1 className='approval-page-text'>Bültene abone olmadınız. Sizi aramızda görmekten mutluluk duyarız.</h1>
    </div>
  )
}

export default DeclinedPage