import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./categoryPage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import MostRead from "./MostRead";
import MostCommented from "./MostCommented";
import TitleBar from "../../components/titleBar/TitleBar";

const FintekCategory = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(18);

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const response = await fetch("https://api.psmmag.com?limit=1000");
        const data = await response.json();

        
        

        
        if (Array.isArray(data)) {
          
          const filteredNews = data.filter(
            (item) =>
              item.categories.includes("Fintek") &&
              item.isDraft === false
          );
          setNewsItems(filteredNews);
        } else if (data && data.news) {
          
          const filteredNews = data.news.filter(
            (item) =>
              item.categories.includes("Fintek") &&
              item.isDraft === false
          );
          setNewsItems(filteredNews);
        } else {
          console.error("API yanıtı beklenen formatta değil:", data);
        }
      } catch (error) {
        console.error("Haberler çekilirken bir hata oluştu:", error);
      }
    };

    fetchNewsItems();
  }, []);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9); // 9 items per load, adjust as needed
  };

  return (
    <div className="category-page-container">
      <div className="category-page-top-container md:grid grid-cols-11 md:px-60">
        <div className="category-page-top-left-container col-span-7 md:pr-[30px]">
        <div className="mb-4 md:hidden"><TitleBar title="Fintek" /></div>
          <div className="category-news-grid-container">
            <div className="category-news-grid">
              {newsItems.slice(0, visibleItems).map((item) => (
                <Link
                  to={`/${item.slug}`}
                  key={item._id}
                  className="category-news-item"
                >
                  <div key={item._id} className="category-news-item">
                    <img src={item.image} alt={item.title} />
                    <div className="category-news-content">
                      <h3 className="title">{item.title}</h3>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="text-center">
            {visibleItems < newsItems.length && (
              <button
                onClick={showMoreItems}
                className="load-more-button category-page-load-more-button"
              >
                Daha Fazla Haber Yükle
              </button>
            )}
          </div>
          <div className="lg:grid grid-cols-2 gap-4 mt-4">
            {/* <MostRead />
                        <MostCommented /> */}
          </div>
        </div>
        <div className="category-page-top-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div>
      </div>
    </div>
  );
};

export default FintekCategory;
