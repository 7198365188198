import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./categoryPage.css";
import { Link } from 'react-router-dom';
import SideMenu from "../../components/sideMenu/SideMenu";
import TitleBar from "../../components/titleBar/TitleBar";

const API_BASE_URL = "https://api.psmmag.com";

const VideoCategory = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState(18);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchNewsItems = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}?limit=5000`);
          const data = response.data;
  
          
  
          if (Array.isArray(data)) {
            const filteredNews = data.filter(
              (item) =>
                item.categories.includes("Video Galeri") && item.isDraft === false
            );
            setNewsItems(filteredNews);
          } else if (data && data.news) {
            const filteredNews = data.news.filter(
              (item) =>
                item.categories.includes("Video Galeri") && item.isDraft === false
            );
            setNewsItems(filteredNews);
          } else {
            console.error("API yanıtı beklenen formatta değil:", data);
          }
        } catch (error) {
          console.error("Haberler çekilirken bir hata oluştu:", error);
        }
      };
  
      fetchNewsItems();
    }, []);
  
    const showMoreItems = () => {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 9);
    };
  
    const handleNewsClick = async (newsId, slug, event) => {
      event.preventDefault();
      try {
        
        await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
  
        
        navigate(`/${slug}`);
      } catch (error) {
        console.error("Error incrementing view count:", error);
        
        navigate(`/${slug}`);
      }
    };

    return (
        <div className='category-page-container'>
            <div className='category-page-top-container md:grid grid-cols-11 md:px-60'>
                <div className='category-page-top-left-container col-span-7 md:pr-[30px]'>
                <div className="mb-4 md:hidden"><TitleBar title="Video Galeri" /></div>
                    <div className="category-news-grid-container">
                        <div className="category-news-grid2">
                            {newsItems.slice(0, visibleItems).map((item) => (
                                <div key={item._id} className="category-news-item">
                                    <Link to={`/${item.slug}`} className="cardImgLink">
                                        <img src={item.image} alt={item.title} className="card-img-top w-100 video-page-img" />
                                        <div className="play-icon"><span class="material-symbols-outlined text-5xl">
play_circle
</span></div>
                                        <div className="category-news-content">
                                            <h3 className="title">{item.title}</h3>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    {visibleItems < newsItems.length && (
                        <div className='text-center'>
                            <button onClick={showMoreItems} className="load-more-button category-page-load-more-button">
                                Daha Fazla Haber Yükle
                            </button>
                        </div>
                    )}
                </div>

                <div className='category-page-top-right-container col-span-4 md:pl-[30px]'>
                    <SideMenu />
                </div>
            </div>
        </div>
    );
}

export default VideoCategory;