import React, { useState } from "react";
import axios from "axios";
import "./footer.css";
import whiteLogo from "../../assets/psm-white-logo.png";
import alohaWhiteLogo from "./aloha-white-logo.png";
import psmLogo from "../../assets/psm-logo.png";
import { FaInstagram, FaLinkedin, FaWhatsapp, FaFacebook, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {

  const [email, setEmail] = useState("");

  const handleButtonClick = async () => {
    if (!email) {
      alert("Lütfen bir e-posta adresi girin.");
      return;
    }

    try {
      const response = await axios.post("https://api.psmmag.com/api/subscribers", { email });
      
      // Handle successful subscription (e.g., show a success message)
      alert("Abonelik başarılı!");
    } catch (error) {
      
      // Handle error (e.g., show an error message)
      alert("Abonelik başarısız oldu. Lütfen tekrar deneyin.");
    }
  };


  return (
    <div>
      <footer className='footer-container  pt-8'>
        <div className='footer-top-container md:pl-52 md:flex gap-4'>
          <div className='footer-top-left-container md:w-80'>
            <img src={psmLogo} alt="" className='w-60 ' />
          </div>
          <div className="footer-top-right-container text-gray-800 md:flex items-center text-left">
            <span className="mr-8 ">
              Haberleri güncel olarak e-postanızdan takip edebilirsiniz!
            </span>
            <input
              type="email"
              placeholder="E-posta adresiniz"
              className="footer-email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              className="footer-email-button"
              type="button"
              onClick={handleButtonClick}
            >
              {" "}
              <span class="material-symbols-outlined">
arrow_forward
</span>
            </button>
          </div>
        </div>


        <div className='footer-mid-container md:pl-52 md:pr-24  md:flex md:mt-6 gap-4'>

          <div className='footer-mid-left-container md:w-80 mb-2 leading-9 break-words'>

            <div className='grid grid-cols-6 gap-x-2 text-left text-gray-800'>
              <div>
              <span class="material-symbols-outlined pt-1">
call
</span>
              </div>
              <div className='col-span-5 text-left text-gray-700'><span>+90 216 550 10 61 / 62</span></div>
            </div>

            <div className='grid grid-cols-6 gap-x-2 text-left text-gray-800'>
              <div>
              <span class="material-symbols-outlined pt-1">
mail
</span>
              </div>
              <div className='col-span-5 text-left text-gray-700'><span>bbekar@akilliyasamdergisi.com</span></div>
            </div>

            <div className='grid grid-cols-6 gap-x-2 text-left text-gray-800'>
              <div>
              <span class="material-symbols-outlined pt-1">
location_on
</span>
              </div>
              <div className='col-span-5 text-left text-gray-700'>Osmanağa Mah. Hasırcıbaşı Cad. Hasırcıbaşı Apt. No:15/3 Kadıköy/İstanbul</div>
            </div>



          </div>

          <div className='footer-mid-right-container md:grid grid-flow-col auto-cols-max gap-14 text-gray-700 text-left leading-9'>

            <div className='md:grid grid-flow-row auto-rows-max'>

              <div><a href="/contact" className='hover:text-gray-500'>İletişim</a></div>
              <div><a href="/kunye" className='hover:text-gray-500'>Künye</a></div>
              <div><a href="/about" className='hover:text-gray-500'>Hakkımızda</a></div>
              <div>
                <a href="/gizlilik-politikasi" className="hover:text-gray-500">
                  Gizlilik Politikası
                </a>
              </div>
              <div>
                <a href="/aydinlatma-metni" className="hover:text-gray-500">
                  Aydınlatma Metni
                </a>
              </div>
              

            </div>
            <hr className='md:hidden my-2' />
            <div className='md:grid grid-flow-row auto-rows-max social-media-container'>
              <div className='md:mb-2 font-semibold'>BİZİ TAKİP EDİN</div>
              <div className='grid grid-flow-col auto-cols-max gap-3 text-gray-700 text-2xl text-left leading-9 '>
                <div>
                  <a href="https://twitter.com/PSMMAGcom" className='hover:text-[#50ABF1] transition-all' target="_blank" rel="noreferrer">
                    <div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                      <FaXTwitter />

                    </div>
                  </a>
                </div>

                <div>
                  <a href="https://www.instagram.com/psmdergisi/" className='hover:text-[#FE1D80] transition-all' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                    <FaInstagram />

                  </div></a>
                </div>

                <div>
                  <a href="https://www.youtube.com/@psmdergisi9390" className='hover:text-[#FF0000] transition-all' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                    <FaYoutube />
                  </div></a>
                </div>

                <div>
                  <a href="https://www.linkedin.com/company/g%C3%BCndem-medya-grubu/" className='hover:text-[#0274B3] transition-all' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                    <FaLinkedin />
                  </div></a>
                </div>
                <div>
                  <a href="https://wa.me/905324627687" className='hover:text-[#26C943] transition-all' target="_blank" rel="noreferrer"><div className=' grid grid-flow-col auto-cols-max items-center gap-3'>
                    <FaWhatsapp />
                  </div></a>
                </div>

              </div>
            </div>

          </div>

          <hr className='md:hidden my-2 text-gray-200' />

          <div className='footer-mid-left-container-mobile md:hidden md:w-80 mb-2 leading-9 break-normal'>

            <div className='grid grid-cols-9 gap-x-2 text-left text-gray-200'>
              <div>
              <span class="material-symbols-outlined text-gray-700 pt-1">
call
</span>
              </div>
              <div className='col-span-8 text-left text-gray-400'><span>+90 216 550 10 61 / 62</span></div>
            </div>
            <div className='grid grid-cols-9 gap-x-2 text-left text-gray-200'>
              <div>
              <span class="material-symbols-outlined text-gray-700 pt-1">
mail
</span>
              </div>
              <div className='col-span-8 text-left text-gray-400'><span>bbekar@akilliyasamdergisi.com</span></div>
            </div>

            <div className='grid grid-cols-9 gap-x-2 text-left text-gray-200'>
              <div>
              <span class="material-symbols-outlined text-gray-700 pt-1">
location_on
</span>
              </div>
              <div className='col-span-8 text-left text-gray-400'>Osmanağa Mah. Hasırcıbaşı Cad. Hasırcıbaşı Apt. No:15/3 Kadıköy/İstanbul</div>
            </div>
          </div>

        </div>


        <div className='footer-bottom-container  h-14 md:mt-8 md:flex justify-center items-center'>
          {/* <a href="https://www.alohadijital.com/" target="_blank"><img className='h-[30px] mx-2' src={alohaWhiteLogo} alt="" /></a> */}
          <span className='footer-bottom-text text-neutral-700 md:text-sm font-medium border-r border-neutral-400 pr-8'> 
            PSM Magazine © 2024 - Tüm hakları saklıdır.
            </span>
          <div className='web-footer-hr border-l border-neutral-400 mx-3 text-neutral-400'> </div>
          <div className="md:hidden mobile-footer-hr border-b border-neutral-400 mx-8 my-2 text-neutral-400"></div>
          <span className='footer-bottom-text text-neutral-700 md:text-sm font-medium'>
            <a className='font-semibold hover:underline' href="https://www.alohadijital.com/" target="_blank">Aloha Dijital</a> tarafından yapılmıştır.
            </span>
        </div>
      </footer>


    </div>
  )
}

export default Footer
