import React, { useState, useEffect } from "react";
import "./sdcards.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const API_BASE_URL = "https://api.psmmag.com";

const Card = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [videoItems, setVideoItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(4);
  const [error, setError] = useState(null);
  const [newsItems, setNewsItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}?limit=5000`);
        const data = response.data;

        // console.log("API response:", data); 

        if (Array.isArray(data)) {
          const filteredNews = data.filter(
            (item) =>
              item.categories.includes("Video Galeri") && item.isDraft === false
          );
          setNewsItems(filteredNews);
        } else if (data && data.news) {
          const filteredNews = data.news.filter(
            (item) =>
              item.categories.includes("Video Galeri") && item.isDraft === false
          );
          setNewsItems(filteredNews);
        } else {
          console.error("API yanıtı beklenen formatta değil:", data);
          setError("API'den gelen veri formatı beklenenden farklı.");
        }
      } catch (error) {
        console.error("Haberler çekilirken bir hata oluştu:", error);
        setError("Haberler çekilirken bir hata oluştu.");
      } finally {
        setLoading(false); 
      }
    };

    fetchNewsItems();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % newsItems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [newsItems]);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9);
  };

  const handleMouseEnter = (index) => {
    setCurrentIndex(index);
  };

  const handleImageLoad = () => {
    setLoading(false); 
  };

  const currentNews = newsItems[currentIndex] || {};

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  // if (newsItems.length === 0) return <div>Hiç haber bulunamadı.</div>;

  return (
    <>
      <div id="sdcrs" className="main-card">
        <div className="image-container">
          {loading && <div className="loading">Yükleniyor...</div>}
          <a href={currentNews?.slug}>
          <img
            src={currentNews?.image}
            alt="Main"
            onLoad={handleImageLoad}
            style={{ display: loading ? "none" : "block" }}
          /></a>
          <div className="category">
            <a href="/video-galeri" target="_blank" className="category-btn">
              VİDEO GALERİ
            </a>
          </div>
          <h2 className="text-left">{currentNews?.title}</h2>
        </div>
        <div className="sub-cards mt-3">
          {newsItems.slice(0, visibleItems).map((item, index) => (
            <Link
              to={`/${item.slug}`}
              key={item._id}
              target="_blank"
              className="sub-card"
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <img src={item.image} alt="Sub" />
              <div className="sub-card-text">
                <h3 style={{ textAlign: "left" }}>{item.title}</h3>
                <p style={{ textAlign: "left", fontSize: 12 }}>
                  {item.date}
                  {/* / {news.comments} Yorum */}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Card;
