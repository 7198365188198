import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
// import { Helmet } from "react-helmet";
import "./contentPage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import CommentSection from "../../components/comments/CommentSection";
import axios from "axios";
import { FaInstagram, FaLinkedin, FaWhatsapp, FaFacebook, FaYoutube, } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const ContentPage = () => {
  const [newsData, setNewsData] = useState({});
  const [newsItems, setNewsItems] = useState([]);
  const [comments, setComments] = useState([]);
  const [notification, setNotification] = useState("");
  const navigate = useNavigate();

  const { slug } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const response = await axios.get(
          `https://api.psmmag.com/slug/${slug}`
        );
        const { data } = response;
        setNewsData(data);

        
        const allNewsResponse = await axios.get(
          `https://api.psmmag.com?limit=36`
        );
        const newsItemsData = allNewsResponse.data.news; 
  
        
        const filteredNewsItems = newsItemsData.filter(item => !item.isDraft);
        setNewsItems(filteredNewsItems);

        
        const commentsResponse = await axios.get(
          `${apiUrl}api/comments/${data._id}`
        );
        const visibleComments = commentsResponse.data.filter(
          (comment) => comment.isVisible === true
        );
        setComments(visibleComments);
      } catch (error) {
        console.error("Veri getirilirken hata oluştu:", error);
      }
    };

    fetchData();
  }, [slug, apiUrl]);

  const handleCommentSubmit = async (commentData) => {
    try {
      const response = await axios.post(`https://api.psmmag.com/api/comments`, {
        ...commentData,
        newsId: newsData._id,
      });
      setNotification("Yorumunuz onaya gönderildi");
      setTimeout(() => setNotification(""), 5000);
    } catch (error) {
      console.error("Yorum gönderilirken hata oluştu:", error);
    }
  };

  const renderNewsText = (text) => {
    return { __html: text };
  };

  const shareUrl = window.location.href;

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const shareOnLinkedIn = () => {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const shareOnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const MAX_TITLE_LENGTH = 30;

  const getCategoryLink = (category) => {
    switch (category) {
      case "Gündem":
        return "/gundem";
      case "Banka":
        return "/banka";
      case "Fintek":
        return "/fintek";
      case "Atm & Pos":
        return "/atm-pos";
      default:
        return "#";
    }
  };

  const handleTagClick = (tag) => {
    navigate(`/tags/${encodeURIComponent(tag)}`);
  };

  return (
    <div className="content-page-container">
      
      {/* <Helmet>
        <title>{newsData.title || "PSM Magazin Haber"}</title>
        <meta name="description" content={newsData.summary || "PSM Magazin'deki habere göz atın"} />
        <meta property="og:title" content={newsData.title} />
        <meta property="og:description" content={newsData.summary} />
        <meta property="og:image" content={newsData.image} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={newsData.title} />
        <meta name="twitter:description" content={newsData.summary} />
        <meta name="twitter:image" content={newsData.image} />
      </Helmet> */}


      <div className="page-link-container md:px-60 items-center flex flex-wrap text-left text-base mt-6 font-medium">
        <a href="/" className="hover:underline">
          PSM Magazin
        </a>
        <span className="material-symbols-outlined">
          chevron_right
        </span>
        <Link
          to={getCategoryLink(
            newsData.categories && newsData.categories.length > 0
              ? newsData.categories[0]
              : ""
          )}
          className="hover:underline"
        >
          {newsData.categories &&
            newsData.categories.length > 0 &&
            newsData.categories[0]}
        </Link>
        <span className="material-symbols-outlined">
          chevron_right
        </span>
        <a href="#" className="hover:underline">
          {newsData.title}
        </a>
      </div>

      <div className="content-page-header md:px-60 py-6">
        <h1 className="text-4xl font-bold mb-3">{newsData.title}</h1>
        <p className="text-base mb-2">{newsData.summary}</p>
        <div className="flex items-center text-gray-600">
          <div>
            <p className="text-xs">
              Yayınlama: {newsData.updateDate &&
                new Date(newsData.updateDate).toLocaleString()}{" "}
              • {newsData.commentCount} Yorum{" "}
              <span className="material-symbols-outlined text-sm">
                chat_bubble
              </span>
            </p>
            {newsData.lastUpdatedBy && newsData.lastUpdatedBy !== ' ' && (
              <p className="text-xs mt-2">
                Güncelleme: {new Date(newsData.lastContentUpdate).toLocaleString()}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="content-container md:grid grid-cols-11 md:px-60">
        <div className="content-left-container col-span-7 md:pr-[30px]">
          {newsData.videoCode ? (
            <div className="video-container mb-4">
              <iframe
                width="100%"
                height="400"
                src={`https://www.youtube.com/embed/${newsData.videoCode}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={newsData.title}
              ></iframe>
            </div>
          ) : (
            <img src={newsData.image} alt="" className="content-image mb-4" />
          )}

          <div className="share-card-container text-white mt-8 mb-4">
            <div className="h-14 bg-[#780006] pl-6 flex items-center">
              <span className="material-symbols-outlined mr-4">
                share
              </span>
              <span className="font-bold mr-6">PAYLAŞ</span>

              <div className="share-link-container text-lg flex gap-2">
                <a
                  onClick={shareOnFacebook}
                  className="hover:bg-[#0867FF] hover:border-[#0867FF] cursor-pointer"
                >
                  <FaFacebook />
                </a>
                <a
                  onClick={shareOnTwitter}
                  className="hover:bg-[#50ABF1] hover:border-[#50ABF1] cursor-pointer"
                >
                  <FaXTwitter />
                </a>
                <a
                  onClick={shareOnLinkedIn}
                  className="hover:bg-[#0274B3] hover:border-[#0274B3] cursor-pointer"
                >
                  <FaLinkedin />
                </a>
                <a
                  onClick={shareOnWhatsApp}
                  className="hover:bg-[#26CA43] hover:border-[#26CA43] cursor-pointer"
                >
                  <FaWhatsapp />
                </a>
              </div>
            </div>
            <div className="min-h-14 bg-[#C90913] pl-6 flex items-center">
              <span className="material-symbols-outlined mr-4">
                sell
              </span>{" "}
              <span>
              {newsData.tags &&
              newsData.tags.map((tag, index) => (
                <React.Fragment key={index}>
                  
                  <a
                    href="#"
                    className="hover:underline cursor-pointer"
                    onClick={() => handleTagClick(tag)}
                  >
                    {tag}
                  </a>
                  {index < newsData.tags.length - 1 && ", "}
                </React.Fragment>
              ))}
              </span>
            </div>
          </div>

          <div className="news-content-text" dangerouslySetInnerHTML={renderNewsText(newsData.newsText)} />

          <CommentSection
            comments={comments}
            onCommentSubmit={handleCommentSubmit}
            notification={notification}
          />

<div className="bg-[#F5F5F5] text-[#373737] pt-10 px-8 ">
            <h3 className="text-lg font-semibold">DİĞER HABERLER</h3>
            <div className="pb-2">
              <div className="news-grid-container mt-4">
                <div className="news-grid">
                  {Array.isArray(newsItems) && newsItems.length > 0 ? (
                    newsItems.map((item) => {
                      const title =
                        item.title.length > MAX_TITLE_LENGTH
                          ? `${item.title.slice(0, MAX_TITLE_LENGTH)}...`
                          : item.title;

                      return (
                        <div key={item.id} className="news-item">
                          <Link to={`/${item.slug}`} target="_blank">
                            <img src={item.image} alt={item.title} />
                          </Link>

                          <div className="news-content">
                            <p className="date">{item.date}</p>
                            <Link to={`/${item.slug}`} target="_blank">
                              <h3 className="title">{title}</h3>
                            </Link>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No news items available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="content-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div>
      </div>
    </div>
  );
};

export default ContentPage;
