import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./specialnewscard.css";

const API_BASE_URL = "https://api.psmmag.com";
const EXCLUSIVE_NEWS_URL = `${API_BASE_URL}/left-headlines?limit=20`; // Son 20 haberi çekmek için limit parametresi

const SpecialNewsCard = () => {
  const [subCards, setSubCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [fade, setFade] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 4;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(EXCLUSIVE_NEWS_URL);
        const newsData = Array.isArray(response.data)
          ? response.data
          : response.data.news;

        if (Array.isArray(newsData)) {
          const filteredNews = newsData.filter((news) => !news.isDraft);
          setSubCards(filteredNews);
          setTotalPages(Math.ceil(filteredNews.length / itemsPerPage));
        } else {
          console.error("API yanıtı bir dizi değil:", response.data);
        }
      } catch (error) {
        console.error("Veri çekerken hata oluştu:", error);
      }
    };

    fetchData();
  }, []);

  const handlePrev = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage > 0 ? prevPage - 1 : totalPages - 1
      );
      setFade(true);
    }, 500);
  };

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage < totalPages - 1 ? prevPage + 1 : 0
      );
      setFade(true);
    }, 500);
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      navigate(`/${slug}`);
    } catch (error) {
      console.error("Error incrementing view count:", error);
      navigate(`/${slug}`);
    }
  };

  const startIndex = currentPage * itemsPerPage;
  const currentSubCards = subCards.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="flex flex-row special-news-card-slider-cards-content">
      <div className="special-news-card-slider-card">
        <div className={`sub-cards ${fade ? "fade-in" : "fade-out"}`}>
          {currentSubCards.map((card, index) => (
            <div
              className="special-news-card-sub-card cursor-pointer"
              key={index}
              onClick={(e) => handleNewsClick(card._id, card.slug, e)}
            >
              <img
                src={card.image}
                alt="Sub"
                className="special-news-card-sub-card-image"
              />
              <div className="special-news-card-sub-card-text">
                {card.title}
              </div>
            </div>
          ))}
        </div>
        <div className="navigation">
          <div>
            <span className="nav-button-left" onClick={handlePrev}>
            <span class="material-symbols-outlined">
chevron_left
</span>
            </span>
            <span className="nav-button-right" onClick={handleNext}>
            <span class="material-symbols-outlined">
chevron_right
</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialNewsCard;
