import React, { useState, useEffect } from "react";
import axios from "axios";
import "./actualNews.css";

const API_BASE_URL = "https://api.psmmag.com";

const CategoryNewsPage = () => {
  const [activeCategory, setActiveCategory] = useState("Gündem");
  const [currentPage, setCurrentPage] = useState(0);
  const [visibleCards, setVisibleCards] = useState(18);
  const [articles, setArticles] = useState([]);
  const itemsPerPage = 18;

  const buttons = [
    { title: "Gündem", link: "/gundem" },
    { title: "Banka & Kredi Kartları", link: "/banka" },
    { title: "Fintek", link: "/fintek" },
    { title: "Atm & Pos", link: "/atm-pos" },
  ];

  const categories = [
    { id: "Gündem", name: "Gündem", link: "/gundem" },
    { id: "Banka", name: "Banka", link: "/banka" },
    { id: "Fintek", name: "Fintek", link: "/fintek" },
    { id: "Atm & Pos", name: "ATM & POS", link: "/atm-pos" },
  ];

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}?limit=1000`)
      .then((response) => {
        if (Array.isArray(response.data.news)) {
          setArticles(response.data.news);
        } else {
          console.error("API'den dönen veri dizi değil:", response.data);
        }
      })
      .catch((error) => console.error("Error fetching news:", error));
  }, []);

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    setCurrentPage(0);
  };

  const filteredArticles = articles.filter((article) =>
    article.categories.includes(activeCategory)
  );

  const startIndex = currentPage * itemsPerPage;
  const currentArticles = filteredArticles.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const [featuredArticle, setFeaturedArticle] = useState({
    title: "",
    category: "",
    image: "",
  });

  useEffect(() => {
    if (filteredArticles.length > 0) {
      setFeaturedArticle(filteredArticles[0]);
    }
  }, [activeCategory, filteredArticles]);

  const handleArticleClick = async (article, event) => {
    event.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/${article._id}/increment-view`);
      window.location.href = `/${article.slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      window.location.href = `/${article.slug}`;
    }
  };

  const currentContent = filteredArticles[activeCategory] || [];

  return (
    <div className="actualNews">
      <div className="container p-1">
        <div
          className="btn-group d-flex btnTop border-blue-800"
          role="group"
          aria-label="Basic example"
        >
          {categories.map((category) => (
            <button
              key={category.id}
              type="button"
              className={`btn btnTitle btn-light ${
                category.id === activeCategory ? "active" : ""
              }`}
              onClick={() => {
                handleCategoryClick(category.id);
                setVisibleCards(18);
              }}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="mt-2">
          <div className="categories-news-container grid grid-cols-3 gap-3">
            {currentArticles.slice(0, visibleCards).map((article, index) => (
              <div key={article._id} className="lg:col-md-4 mt-1">
                <div className="card d-flex flex-row mb-1">
                  <div
                    className="cardImgLink col-6 cursor-pointer"
                    onClick={(e) => handleArticleClick(article, e)}
                  >
                    <img
                      src={article.image}
                      className="card-img-top w-100 object-cover"
                      alt=""
                    />
                  </div>
                  <div className="card-body p-0 col-6">
                    <div
                      className="m-0 px-2 w-100 h-100 d-flex flex-col justify-center items-center font-medium cursor-pointer actual-news-title"
                      onClick={(e) => handleArticleClick(article, e)}
                    >
                      {article.title}
                      <span className="date w-100 text-right">
                        {article.date}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {visibleCards < currentContent.length && (
            <div className="text-center mt-4">
              {buttons.map((button, index) => (
                <a
                  key={index}
                  href={button.link}
                  className="btn btn-primary mx-2"
                >
                  {button.title}
                </a>
              ))}
            </div>
          )}
          {visibleCards >= currentContent.length && (
            <div className="text-center">
              <a
                href={
                  categories.find((category) => category.id === activeCategory)
                    ?.link
                }
                className="btn btn-secondary"
              >
                Daha Fazla
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryNewsPage;
